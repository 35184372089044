import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SideQuoteForm from "../components/layout/SideQuoteForm"
import HomeMenuDL from "../components/layout/HomeMenuDL"
import Footer from "../components/layout/Footer"
import Carousel from "../components/Carousel"
import BrandGallery from "../components/cms/BrandGallery"
import { useStaticQuery, graphql, Link } from "gatsby"
import { slugify } from "../helpers/StringHelpers"

const CurrentDevDL = () => {
  const { products } = useStaticQuery(
    graphql`
      query {
        products: allStrapiProduct(
          filter: { categories: { elemMatch: { name: { eq: "Chopard Jewelry" } } } }
        ) {
          nodes {
            name
            model
            item_type
            productType
            image {
              name
              url
              width
              height
            }
            url: url_key
          }
        }
      }
    `
  )
  const items = []
  const groups = {}
  products.nodes.forEach(e => {
    const model = e.model || e.productType
    if (!model) return
    if (!Object.hasOwnProperty.bind(groups)(model)) {
      groups[model] = {
        ...e,
        name: model,
        totalCount: 1,
        image: e.image.url,
        url_buy: "https://www.grayandsons.com/",
        price: 1,
      }
      items.push(groups[model])
    }
  })
  const section = {
    categories: [
      {
        priority: 0,
        gallery: items,
      },
    ],
  }

  return (
    <section className={" "}>
      <HomeMenuDL />

      <BrandGallery section={section} />

      <Footer />
    </section>
  )
}

export default CurrentDevDL
